.flicking-viewport {
  position: relative;
  overflow: hidden;
}

.flicking-viewport:not(.vertical) {
  width: 100%;
  height: 100%;
}

.flicking-viewport.vertical,
.flicking-viewport.vertical > .flicking-camera {
  display: inline-block;
}

.flicking-viewport.vertical.middle > .flicking-camera > * {
  vertical-align: middle;
}

.flicking-viewport.vertical.bottom > .flicking-camera > * {
  vertical-align: bottom;
}

.flicking-viewport.vertical > .flicking-camera > * {
  display: block;
}

.flicking-viewport.flicking-hidden > .flicking-camera > * {
  visibility: hidden;
}

.flicking-camera {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  will-change: transform;
}

.flicking-camera > * {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
