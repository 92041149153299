.flicking-viewport {
  position: relative;
  overflow: hidden;
}

.flicking-viewport.vertical {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.flicking-viewport.vertical > .flicking-camera {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.flicking-viewport.flicking-hidden > .flicking-camera > * {
  visibility: hidden;
}

.flicking-camera {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  z-index: 1;
  will-change: transform;
}

.flicking-camera > * {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
